<template>
  <v-container fluid>
    <BaseBreadcrumb
      :title="page.title"
      :icon="page.icon"
      :breadcrumbs="breadcrumbs"
    ></BaseBreadcrumb>
    <v-row>
      <v-col cols="12" sm="12">
        <gestao-cliente-produtos />
      </v-col>
    </v-row>
  </v-container>
</template>


<script>

import GestaoClienteProdutos from '../../components/patio/GestaoClienteProdutos/GestaoClienteProdutos.vue'

export default {
  data() {
    return {
      page: {
        title: 'Gestão de produtos do cliente',
      },
      breadcrumbs: [
        {
          text: 'Pátios',
          disabled: false,
          to: '/patio/patio',
        },
        {
          text: 'Gestão de produtos do cliente',
          disabled: false,
        },
      ],
    }
  },
  components: {
    GestaoClienteProdutos
  },
  methods: {
  }
}
</script>